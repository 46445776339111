// Navigation
export const NAVIGATE_TO = 'NAVIGATE_TO'
export const GO_BACK = 'GO_BACK'

// Scopes
export const SCOPE_BANKS_BUILD = 'banks.build'
export const SCOPE_QUIZ_BUILD = 'quiz.build'
export const SCOPE_QUIZ_ANALYSIS = 'quiz.analysis'
export const SCOPE_QUIZ_SHOW = 'quiz_session.show'
export const SCOPE_QUIZ_TAKE = 'quiz_session.take'
export const SCOPE_QUIZ_RESULTS = 'quiz_session.results'
export const SCOPE_QUIZ_GRADE = 'quiz_session.grade'
export const SCOPE_QUIZ_LOG = 'quiz_sessions.log'
export const SCOPE_QUIZ_MODERATE = 'quiz_sessions.moderate'

// Outcomes Scopes
export const SCOPE_OUTCOME_ALIGNMENT_SETS_CREATE = 'outcome_alignment_sets.create'
export const SCOPE_OUTCOME_REPORTS_SHOW = 'outcome_reports.show'

// ParticipantSession
export const CREATE_PARTICIPANT_SESSION = 'CREATE_PARTICIPANT_SESSION'
export const SET_ACTIVE_PARTICIPANT_SESSION = 'SET_ACTIVE_PARTICIPANT_SESSION'

export const PARTICIPANT_SESSION_GRADED = 'graded'
export const PARTICIPANT_SESSION_IN_PROGRESS = 'in_progress'
export const PARTICIPANT_SESSION_PENDING = 'pending'
export const PARTICIPANT_SESSION_NEEDS_GRADING = 'needs_grading'

// AssignmentSession
export const ASSIGNMENT_CLONING = 'cloning'
export const ASSIGNMENT_FAILED_TO_CLONE = 'failed_to_clone'
export const ASSIGNMENT_SETTINGS_IMPORTED = 'settings_imported'
export const CREATE_OR_UPDATE_ASSIGNMENT_SESSION = 'CREATE_OR_UPDATE_ASSIGNMENT_SESSION'

// OauthToken
export const STORE_OAUTH_TOKEN = 'STORE_OAUTH_TOKEN'
export const SET_LAUNCH_TOKEN = 'SET_LAUNCH_TOKEN'
export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token'
export const TOKEN_REFRESH_WINDOW_MS = 1000 * 60 * 2 // 2 minutes in MS
export const TOKEN_SYNC_REFRESH_WINDOW_MS = 1000 * 30 // 30 seconds in MS
// 25 seconds should be enough to refresh a token in most of the cases
export const TOKEN_SYNC_THROTTLE_WINDOW_MS = 1000 * 25 // 25 seconds in MS
export const CHECK_IF_OAUTH_TOKEN_NEEDS_REFRESH = 'CHECK_IF_OAUTH_TOKEN_NEEDS_REFRESH'

// Sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

// Context
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_RETURN_TO_URL = 'SET_RETURN_TO_URL'
export const STORE_ASSIGNMENT_ID = 'STORE_ASSIGNMENT_ID'
export const SET_TIMEZONE = 'SET_TIMEZONE'
export const SET_PLATFORM = 'SET_PLATFORM'
export const SET_RLDB_LAUNCH = 'SET_RLDB_LAUNCH'
export const SET_SDK_TRANSLATIONS = 'SET_SDK_TRANSLATIONS'
export const SET_COURSE_WORKFLOW_STATE = 'SET_COURSE_WORKFLOW_STATE'
export const SET_ITEM_BANKS_SCOPE = 'SET_ITEM_BANKS_SCOPE'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_MODULE_LAUNCH = 'SET_MODULE_LAUNCH'
export const SET_LAUNCH_URL = 'SET_LAUNCH_HOST'
export const SET_RCS_HOST = 'SET_RCS_HOST'
export const SET_RCS_JWT = 'SET_RCS_JWT'
export const SET_CANVAS_CONTEXT_ID = 'SET_CANVAS_CONTEXT_ID'
export const SET_CANVAS_LOCAL_CONTEXT_ID = 'SET_CANVAS_LOCAL_CONTEXT_ID'
export const SET_CANVAS_TOOL_ID = 'SET_CANVAS_TOOL_ID'
export const SET_USER_CANVAS_ID = 'SET_USER_CANVAS_ID'
export const SET_CONTEXT_TYPE = 'SET_CONTEXT_TYPE'
export const SET_CANVAS_HOST = 'SET_CANVAS_HOST'
export const SET_CANVAS_ASSIGNMENT_ID = 'SET_CANVAS_ASSIGNMENT_ID'
export const SET_RESTRICT_QUANTITATIVE_DATA = 'SET_RESTRICT_QUANTITATIVE_DATA'
export const SET_GRADING_SCHEME = 'SET_GRADING_SCHEME'
export const SET_READ_ONLY = 'SET_READ_ONLY'

// Role Spoofing
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token'

// Moderation
export const ADD_PARTICIPANTS_IN_PROGRESS = 'ADD_PARTICIPANTS_IN_PROGRESS'
export const CREATE_MODERATION_PARTICIPANTS = 'CREATE_MODERATION_PARTICIPANTS'
export const ENROLLMENT_UPDATED = 'ENROLLMENT_UPDATED'
export const PARTICIPANT_UPDATED = 'PARTICIPANT_UPDATED'
export const MODERATE_TRAY_CLOSED = 'MODERATE_TRAY_CLOSED'
export const OPEN_MODERATE_TRAY = 'OPEN_MODERATE_TRAY'
export const UPDATE_MODERATION_PAGE = 'UPDATE_MODERATION_PAGE'
export const UPDATE_MODERATION_PROGRESS_FILTER = 'UPDATE_MODERATION_PROGRESS_FILTER'
export const UPDATE_MODERATION_SEARCH = 'UPDATE_MODERATION_SEARCH'
export const OPEN_ACCOMMODATION_MODAL = 'OPEN_ACCOMMODATION_MODAL'
export const ACCOMMODATION_MODAL_CLOSED = 'ACCOMMODATION_MODAL_CLOSED'
export const UPDATE_ACCOMMODATION = 'UPDATE_ACCOMMODATION'
export const SET_ASSIGNMENT_TIMELIMIT = 'SET_ASSIGNMENT_TIMELIMIT'
export const SET_NUM_AUTOGRADING_FAILED = 'SET_NUM_AUTOGRADING_FAILED'
export const SAVE_ACCOMODATIONS = 'SAVE_ACCOMODATIONS'

// Preview
export const LOAD_PREVIEW_DATA = 'LOAD_PREVIEW_DATA'
export const CLEAR_PREVIEW_DATA = 'CLEAR_PREVIEW_DATA'

// Alerts
export const ADD_ALERT = 'ADD_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

// Timer settings
export const UPDATE_TIMER_SETTINGS = 'UPDATE_TIMER_SETTINGS'

// Give Extra Attempt Per Quiz settings
export const UPDATE_EXTRA_ATTEMPTS_PER_QUIZ = 'UPDATE_EXTRA_ATTEMPTS_PER_QUIZ'

// Give Extra Time Per Quiz settings
export const UPDATE_EXTRA_TIME_PER_QUIZ = 'UPDATE_EXTRA_TIME_PER_QUIZ'

// Features
export const STORE_FEATURES = 'STORE_FEATURES'

// UX toggles
export const TOGGLE_MODAL = 'TOGGLE_MODAL'

// Cooling period timer
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME'

// Users
export const STORE_USERS = 'STORE_USERS'

// Versions
export const STORE_VERSION = 'STORE_VERSION'

// Participants
export const STORE_PARTICIPANTS = 'STORE_PARTICIPANTS'

// Header
export const RENDER_SUBMIT_BUTTON = 'RENDER_SUBMIT_BUTTON'

// Courses
export const STORE_COURSES = 'STORE_COURSES'

// Courses
export const STORE_SUBACCOUNTS = 'STORE_SUBACCOUNTS'

// Course Workflow States (from canvas-lms course model: created, claimed, available, deleted not used currently)
export const COURSE_WORKFLOW_STATE_COMPLETED = 'completed'

// Content Exports
export const STORE_CONTENT_EXPORTS = 'STORE_CONTENT_EXPORTS'
export const EXPORT_STATUS_EXPORTING = 'EXPORT_STATUS_EXPORTING'
export const EXPORT_STATUS_COMPLETE = 'EXPORT_STATUS_COMPLETE'
export const EXPORT_STATUS_FAILED = 'EXPORT_STATUS_FAILED'
