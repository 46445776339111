import debounce from 'debounce'

import {PARTICIPANT_UPDATED} from '../constants'

const postUpdateModeration = debounce((store, action) => {
  const {postActionHandler} = action
  if (postActionHandler) {
    store.dispatch(postActionHandler())
  }
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default store => next => action => {
  if (action.type === PARTICIPANT_UPDATED) {
    postUpdateModeration(store, action)
  }
  return next(action)
}
