import isValidNumberValue from './isValidNumberValue'

export default function extraTimePerQuizValid(settings) {
  if (!settings) {
    return false
  }
  const valid_enable = !(settings.disableTimerQuiz && settings.extraTimeEnabledQuiz)
  const valid_time =
    !settings.extraTimeEnabledQuiz || isValidNumberValue(settings.extraTimeInSecondsQuiz)
  return valid_enable && valid_time
}
